import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-account-delete-instruction',
  templateUrl: './account-delete-instruction.component.html',
  styleUrls: ['./account-delete-instruction.component.scss']
})
export class AccountDeleteInstructionComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
