import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-account-delete-instruction-for-mobile',
  templateUrl: './account-delete-instruction-for-mobile.component.html',
  styleUrls: ['./account-delete-instruction-for-mobile.component.scss']
})
export class AccountDeleteInstructionForMobileComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
